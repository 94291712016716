<template>
    <div class="row">
        <div class="col-md-10">
         <seccion-datos :titulo="configjornadas" :botonGuardar="false">
                    <div class="form-group campos-jornadas">
                        <div class="row">
                            <div class="col-md-5">
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">{{ $t('general.de') }} </span>
                                    </div>
                                    <select v-model="dia_ini" name="dia_ini" id="" class="dia-ini " >
                                        <option value="1">{{ $t('general.lunes') }}</option>
                                        <option value="2">{{ $t('general.martes') }}</option>
                                        <option value="3">{{ $t('general.miercoles') }}</option>
                                        <option value="4">{{ $t('general.jueves') }}</option>
                                        <option value="5">{{ $t('general.viernes') }}</option>
                                        <option value="6">{{ $t('general.sabado') }}</option>
                                        <option value="7">{{ $t('general.domingo') }}</option>
                                    </select> 
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">{{ $t('general.a') }} </span>
                                    </div>
                                    <select v-model="dia_fin" name="dia_fin" id="" class="dia-fin ">
                                        <option value="1">{{ $t('general.lunes') }}</option>
                                        <option value="2">{{ $t('general.martes') }}</option>
                                        <option value="3">{{ $t('general.miercoles') }}</option>
                                        <option value="4">{{ $t('general.jueves') }}</option>
                                        <option value="5">{{ $t('general.viernes') }}</option>
                                        <option value="6">{{ $t('general.sabado') }}</option>
                                        <option value="7">{{ $t('general.domingo') }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-5">
                                <div class="row">
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">{{ $t('general.de') }} </span>
                                        </div>
                                        <select v-model="hora_ini" name="hora_ini" id="" class="hora-ini">
                                            <option  v-for="i in 24" :key="i - 1" :value="i - 1">{{ (i - 1).toString().padStart(2, '0') }}</option>
                                        </select>
                                        <div class="input-group-append">
                                            <span class="input-group-text">:</span>
                                        </div>
                                        <select v-model="minuto_ini" name="minuto_ini" id="" class="minuto-ini">
                                            <option v-for="i in 60" :key="i - 1" :value="i - 1">{{ (i - 1).toString().padStart(2, '0') }}</option>
                                        </select>
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"> {{ $t('general.a') }} </span>
                                        </div>
                                        <select v-model="hora_fin" name="hora_fin" id="" class="hora-fin">
                                            <option v-for="i in 24" :key="i - 1" :value="i - 1">{{ (i - 1).toString().padStart(2, '0') }}</option>
                                        </select>
                                        <div class="input-group-append">
                                            <span class="input-group-text">:</span>
                                        </div>
                                        <select v-model="minuto_fin" name="minuto_fin" id="" class="minuto-fin">
                                            <option v-for="i in 60" :key="i - 1" :value="i - 1">{{ (i - 1).toString().padStart(2, '0') }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <button class="btn btn-primary agregar form-control" @click="guardar(id)">{{ $t('general.agregar') }}</button>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <table class="table  table-sm table-striped">
                            <tbody>
                                <tr v-for="jornada in jornadas" :key="jornada.id_jornada">
                                    <td width="80%">{{ dias[jornada.dia_inicio_jornada] }} - {{ dias[jornada.dia_fin_jornada] }} {{ jornada.hora_inicio_jornada.substring(0, 5) }}h - {{ jornada.hora_fin_jornada.substring(0, 5) }}h </td>
                                    <td><button type="button" class="btn btn-block btn-outline-danger btn-xs" @click="eliminarJornada(id,jornada.id_jornada)">{{ $t('general.eliminar') }}</button></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
            </seccion-datos>
        </div>
    </div>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
export default {
    props: ['usuario','id'],
    data() {
        return {
            dia_ini: '1',
            dia_fin: '5',
            hora_ini: '0',
            minuto_ini: '0',
            hora_fin: '0',
            minuto_fin: '0',
            dias: [ "dia","Lunes","Martes","Miercoles","Jueves","Viernes","Sabado","Domingo"],
            jornadas: [],
            configjornadas: "Configuraciones jornadas"
        }
    },
    methods:
    {
        async guardar(id) {   
            /* post  modulos/pwgsapi/index.php/usuarios/:id/jornadas */
            const horaInicio = (this.hora_ini.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ":" + this.minuto_ini.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ":" + "0" + "0");
            const horaFin = (this.hora_fin.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ":" + this.minuto_fin.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ":" + "0" + "0");
            const api = new PwgsApi;
            const subidadatos = { dia_inicio_jornada: this.dia_ini, dia_fin_jornada: this.dia_fin, hora_inicio_jornada: horaInicio, hora_fin_jornada: horaFin};
            await api.post('usuarios/' + id + '/jornadas', subidadatos);
            this.obtenerJornadas(id);
        },
        async obtenerJornadas(id) {
            /* get  modulos/pwgsapi/index.php/usuarios/:id/jornadas */
            this.$store.dispatch('cambiarCargando', true);
            const api = new PwgsApi;
            const subida = await api.get('usuarios/' + id + '/jornadas');
            this.jornadas = subida.datos;
            this.$store.dispatch('cambiarCargando', false);
            this.configjornadas = this.$t("general.configuracionesjornadas");
            this.dias[1] = this.$t('general.lunes');
            this.dias[2] = this.$t('general.martes');
            this.dias[3] = this.$t('general.miercoles');
            this.dias[4] = this.$t('general.jueves');
            this.dias[5] = this.$t('general.viernes');
            this.dias[6] = this.$t('general.sabado');
            this.dias[7] = this.$t('general.domingo');

        },
        async eliminarJornada(id,idelete) {
            /* delete  modulos/pwgsapi/index.php/usuarios/:id/jornadas/:id_jornada*/
            this.$store.dispatch('cambiarGuardando', true);
            const api = new PwgsApi;
            await api.delete('usuarios/' + id + '/jornadas/' + idelete);
            this.$store.dispatch('cambiarGuardando', false);
            this.obtenerJornadas(id);
        },
        },
    mounted() {
        this.obtenerJornadas(this.id);
    },
    watch:
        {
        id() {
                this.obtenerJornadas(this.id);
            },   
        }
}
</script>