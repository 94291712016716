<template>
    <div class="card card-widget widget-user-2">
        <div class="widget-user-header bg-gradient-warning">
            <div class="widget-user-image">
                <img class="img-circle elevation-2" :src="miimagen" @click="$refs.file.click()" alt="User Avatar">
                <input type="file" ref="file" style="display: none">
            </div>
            <h3 class="widget-user-username">{{ usuario.nombre }}</h3>
            <div class="widget-user-desc col-md-6">
                <p>{{usuario.nombre_completo}}</p>
            </div>
        </div>
        <div class="card-footer p-0">
            <ul class="nav flex-column">
                <li class="nav-item"><a href="#" class="nav-link"> {{ $t('general.expedientesactivos') }}<span
                            class="float-right badge bg-primary">{{ num_expedientesactivos }}</span></a></li>
                <!--<li class="nav-item"><a href="#" class="nav-link"> {{ $t('general.expedientescompania') }} <span
                            class="float-right badge bg-warning">12</span></a></li>-->
                <li class="nav-item"><a href="#" class="nav-link" data-toggle="modal" data-target="#modal-default"> {{
                        $t('general.companias') }} <span
                            class="float-right badge bg-info">{{companiasexpedientes}}</span></a></li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    props: ['usuario', 'id', 'num_expedientesactivos','companiasexpedientes'],
    data() {
        return {
            miimagen: 'https://cdn.icon-icons.com/icons2/2483/PNG/512/user_icon_149851.png',
            user:[],
        }
    },
    methods: {
        listadoUsuario() { 
            this.user = this.usuario.nombre;
        }
        },
    watch:{
        usuario() {
            this.listadoUsuario(this.usuario.id);
        }
    },
 }
</script>