<template>
    <div class="row">
        <div class="col-md-8">
                <seccion-datos :titulo="configuracioncarga" @cambios="guardar(this.id)">
                        <table class="table table-bordered carga">
                            <thead>
                                <tr class="bg-lightblue disabled ">
                                    <th>{{ $t('general.cargadiaria') }}</th>
                                    <th>{{ $t('general.maxactivos') }}</th>
                                    <th>{{ $t('general.jornada') }}</th>
                                    <th>{{ $t('general.ratioactivos') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="info">
                                    <td width="27%">
                                        <div class="input-group mb-3">
                                            <input type="number" id="icarga_diaria" name="carga_diaria" class="form-control " style="width:90px" v-model="carga_diaria" /> 
                                            <div class="input-group-append">
                                            </div>
                                        </div>   
                                    </td>
                                    <td width="27%">
                                        <div class="input-group mb-3">
                                            <input type="number" id="imax_activos" v-model="max_activos"  class="form-control " style="width:90px"  /> 
                                            <div class="input-group-append">
                                            </div>
                                        </div>  
                                    </td>
                                    <td width="27%">
                                        <div class="input-group mb-3">
                                            <input type="number" id="iperiodo_activos" v-model="periodo_activos" name="carga_diaria3" class="form-control " style="width:90px" /> 
                                            <div class="input-group-append">
                                            </div>
                                        </div>  
                                    </td>
                                    <td width="19%"><span class="dato-carga ratio_activos"></span></td>
                                </tr>
                                <tr class="alerta">  
                                    <!--<td><span class="dato-carga max_carga_diaria_calc"></span></td>
                                    <td><span class="dato-carga activos_calc"></span> <span title='Expedientes activos que no tienen marcado "Finalizar expediente" en "Trabajos realizados"'><span class="dato-carga no_finalizados"></span></span></td>-->                  
                                </tr>
                            </tbody>
                        </table>
            </seccion-datos>
        </div>
    </div>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
export default {
    props: ['usuario','id'],
    data() {
        return {
            carga_diaria: '',
            max_activos: '',
            periodo_activos: '',
            configuracioncarga: "Configuración de niveles de carga",
        }
    },
    methods: {
        async cargardatos() {
            this.$store.dispatch('cambiarCargando', true);
            this.carga_diaria = this.usuario.carga_diaria;
            this.max_activos = this.usuario.max_activos;
            this.periodo_activos = this.usuario.periodo_activos;
            this.$store.dispatch('cambiarCargando', false);
            this.configuracioncarga = this.$t('general.configuracioncarga');
        },
        async guardar(id) {
            /* put  modulos/pwgsapi/index.php/usuarios/:id/carga */
            this.$store.dispatch('cambiarGuardando', true);
            const datosSubida = { carga_diaria: this.carga_diaria, max_activos: this.max_activos, periodo_activos: this.periodo_activos };
            try {
                const api = new PwgsApi;
               await api.put('usuarios/' + id + '/carga', datosSubida);
                this.$store.dispatch('cambiarGuardando', false);
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
            }
            catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'El trabajo no se puede modificar ya que está incluido en otro presupuesto generado', life: 5000 });
            }
        }
    },
    mounted() {
        this.cargardatos();
    },

    watch: {
        usuario() {
            this.cargardatos();
        }
    }
    }
</script>